/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
  background-color: #f6f2f8;
}

.w-100 {
  width: 100%;
}

.header-image {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-image-primary {
  background-color: #005cbb;
}

.header-image-warn {
  background-color: #ba1a1a;
}

.subtitle-spacing {
  margin-bottom: 16px !important;
}
